import {createRouter, createWebHashHistory} from 'vue-router';
import type {RouteRecordRaw} from 'vue-router';

const LoginView = () => import('@/views/FoodGuest/LoginView.vue');
const ImprintView = () => import('@/views/FoodGuest/ImprintView.vue');
const LogoutView = () => import('@/views/FoodGuest/LogoutView.vue');
const NotFound = () => import('@/views/FoodGuest/NotFound.vue');
const Error = () => import('@/views/Error.vue');

const FoodVendorApplication = () => import('@/views/FoodGuest/ApplicationView.vue');
const ActivateFoodVendor = () => import('@/views/FoodGuest/ActivateFoodVendor.vue');
const PasswordReset = () => import('@/views/FoodGuest/PasswordReset.vue');
const PasswordResetRequest = () => import('@/views/FoodGuest/PasswordResetRequest.vue');

const Locations = () => import('@/views/LocationManagement.vue');
const FoodTaxProfiles = () => import('@/views/FoodAccountant/FoodTaxProfiles.vue');
const FoodCosts = () => import('@/views/FoodAccountant/FoodCosts.vue');

const FoodProjects = () => import('@/views/FoodAdmin/FoodProjects.vue');
const FoodProject = () => import('@/views/FoodAdmin/FoodProject.vue');
const FoodVendor = () => import('@/views/FoodAdmin/FoodVendor.vue');
const FoodVendors = () => import('@/views/FoodAdmin/FoodVendors.vue');

const FoodAccountantInvoices = () => import('@/views/FoodAccountant/FoodInvoices.vue');
const FoodAccountantInvoicesUnsettled = () => import('@/views/FoodAccountant/FoodInvoicesUnsettled.vue');
const FoodProjectAccount = () => import('@/views/FoodAdmin/FoodProjectAccount.vue');

const FoodVendorProfile = () => import('@/views/FoodVendor/Profile.vue');
const FoodVendorKiosks = () => import('@/views/FoodVendor/Kiosks.vue');
const FoodVendorAssortments = () => import('@/views/FoodVendor/Assortments.vue');
const FoodVendorEvents = () => import('@/views/FoodVendor/Events.vue');
const FoodVendorInvoices = () => import('@/views/FoodVendor/Invoices.vue');
const FoodVendorEvent = () => import('@/views/FoodVendor/Event.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: {name: 'application'},
  },
  {
    path: '/',
    redirect: {name: 'application'},
  },
  {
    name: 'login',
    path: '/login',
    component: LoginView,
  },
  {
    name: 'application',
    path: '/application',
    component: FoodVendorApplication,
  },
  {
    name: 'activate',
    component: ActivateFoodVendor,
    path: '/activate/:newUserEmailId',
    props: true,
  },
  {
    name: 'password_reset',
    component: PasswordReset,
    path: '/password_reset/:passwordResetRequestId',
    props: true,
  },
  {
    name: 'password_reset_request',
    component: PasswordResetRequest,
    path: '/password_reset_request',
  },
  {
    name: 'imprint',
    path: '/imprint',
    component: ImprintView,
  },
  {
    name: 'logout',
    path: '/logout',
    component: LogoutView,
  },
  {
    name: 'not_found',
    path: '/:pathMatch(.*)',
    component: NotFound,
  },
  {
    name: 'locations',
    component: Locations,
    path: '/locations',
  },
  {
    name: 'tax_profiles',
    component: FoodTaxProfiles,
    path: '/tax_profiles',
  },
  {
    name: 'accountant_costs',
    component: FoodCosts,
    path: '/accountant_costs',
  },
  {
    name: 'food_projects',
    path: '/food_projects',
    component: FoodProjects,
  },
  {
    name: 'food_project',
    component: FoodProject,
    path: '/food_projects/:projectId',
    props: true,
  },
  {
    name: 'food_project_account',
    component: FoodProjectAccount,
    path: '/food_project_account/:projectId',
    props: true,
  },
  {
    name: 'food_vendors',
    path: '/food_vendors',
    component: FoodVendors,
  },
  {
    name: 'food_vendor',
    component: FoodVendor,
    path: '/food_vendors/:foodVendorId',
    props: true,
  },
  {
    name: 'food_vendor_profile',
    component: FoodVendorProfile,
    path: '/food_vendor_profile',
  },
  {
    name: 'food_vendor_kiosks',
    component: FoodVendorKiosks,
    path: '/kiosks',
  },
  {
    name: 'food_vendor_assortments',
    component: FoodVendorAssortments,
    path: '/assortments',
  },
  {
    name: 'food_vendor_events',
    component: FoodVendorEvents,
    path: '/events',
  },
  {
    name: 'food_vendor_invoices',
    component: FoodVendorInvoices,
    path: '/invoices',
  },
  {
    name: 'food_vendor_event',
    component: FoodVendorEvent,
    path: '/event/:projectId',
    props: true,
  },
  {
    name: 'food_invoices',
    component: FoodAccountantInvoices,
    path: '/food_invoices',
  },
  {
    name: 'food_invoices_unsettled',
    component: FoodAccountantInvoicesUnsettled,
    path: '/food_invoices/unsettled',
  },
  {
    name: 'error',
    path: '/error',
    component: Error,
  },
];

export default createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
});
